import React, { useState, useEffect, useCallback } from 'react';
import { get } from '../../services/api';
import { useParams } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const InstallmentPlanDetails = () => {
    const [plan, setPlan] = useState(null);
    const { planId } = useParams();

    const fetchPlanDetails = useCallback(async () => {
        try {
            const response = await get(`/api/installment-plan/${planId}`);
            setPlan(response.data);
        } catch (error) {
            console.error('Error fetching plan details:', error);
        }
    }, [planId]);

    useEffect(() => {
        fetchPlanDetails();
    }, [fetchPlanDetails]);

    if (!plan) return <div>Loading...</div>;

    const percentage = ((plan.total_amount - plan.remaining_balance) / plan.total_amount) * 100;
    const nextInstallment = plan.installments.find(i => i.status === 'Pending');

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Installment Plan #{plan.id}</h2>
            
            <div className="mb-8 w-64 mx-auto">
                <CircularProgressbar
                    value={percentage}
                    text={`₹${plan.remaining_balance.toFixed(2)}`}
                    styles={buildStyles({
                        textSize: '16px',
                        pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                        textColor: '#000',
                        trailColor: '#d6d6d6',
                        backgroundColor: '#3e98c7',
                    })}
                />
                <div className="text-center mt-4">
                    <p>Total Amount: ₹{plan.total_amount.toFixed(2)}</p>
                    <p>Service Fee: ₹{plan.service_fee.toFixed(2)}</p>
                    <p>Initial Payment: ₹{plan.initial_payment.toFixed(2)}</p>
                    <p>Remaining Balance: ₹{plan.remaining_balance.toFixed(2)}</p>
                    {nextInstallment && (
                        <p className="font-bold mt-2">
                            Next payment of ₹{nextInstallment.amount.toFixed(2)} due on {new Date(nextInstallment.due_date).toLocaleDateString()}
                        </p>
                    )}
                </div>
            </div>

            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h3 className="text-xl font-semibold mb-2">Installments</h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Amount</th>
                                <th className="py-2 px-4 border-b">Due Date</th>
                                <th className="py-2 px-4 border-b">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {plan.installments.map(installment => (
                                <tr key={installment.id}>
                                    <td className="py-2 px-4 border-b">₹{installment.amount.toFixed(2)}</td>
                                    <td className="py-2 px-4 border-b">{new Date(installment.due_date).toLocaleDateString()}</td>
                                    <td className="py-2 px-4 border-b">{installment.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {nextInstallment && (
                <div className="mt-4 flex justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded">
                        Pay Now (₹{nextInstallment.amount.toFixed(2)})
                    </button>
                </div>
            )}
        </div>
    );
};

export default InstallmentPlanDetails;