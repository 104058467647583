import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../services/api';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import UserNavBar from './UserNavBar';
import { DemoContext } from '../../DemoContext';
import { useDemoTimer } from '../../hooks/useDemoTimer';

const Dashboard = () => {
    const [user, setUser] = useState(null);
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isDemo, startDemo, endDemo, demoCreditCheck, demoTransactions } = useContext(DemoContext);
    const { timeLeft, startTimer, resetTimer } = useDemoTimer();

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const [userResponse, transactionsResponse] = await Promise.all([
                    get('/profile'),
                    get('/user/transactions')
                ]);
                setUser(userResponse.data);
                setRecentTransactions(transactionsResponse.data.slice(0, 5));
                setLoading(false);
            } catch (err) {
                console.error('Error fetching dashboard data:', err);
                setError('Failed to fetch dashboard data: ' + err.message);
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, [isDemo]);

    const handleStartDemo = () => {
        startDemo();
        startTimer();
    };

    const handleEndDemo = () => {
        endDemo();
        resetTimer();
    };

    if (loading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
    if (error) return <div className="flex items-center justify-center min-h-screen text-red-500">{error}</div>;

    const displayUser = isDemo && demoCreditCheck
        ? { ...user, credit_status: demoCreditCheck.credit_status, credit_limit: demoCreditCheck.credit_limit }
        : user;

    const displayTransactions = isDemo ? [...demoTransactions, ...recentTransactions] : recentTransactions;

    // Mock data for the spending chart (you can replace this with real data)
    const spendingData = [
        { name: 'Jan', amount: 4000 },
        { name: 'Feb', amount: 3000 },
        { name: 'Mar', amount: 2000 },
        { name: 'Apr', amount: 2780 },
        { name: 'May', amount: 1890 },
        { name: 'Jun', amount: 2390 },
    ];

    return (
        <>
            <UserNavBar />
            <div className="min-h-screen bg-white pt-24 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    <div className="bg-white shadow-lg rounded-lg p-8">
                        <h1 className="text-3xl font-bold mb-6 text-center text-purple-600">Welcome to Your Dashboard</h1>
                        
                        {isDemo && (
                            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
                                <p className="font-bold">Demo Mode Active</p>
                                <p>Time remaining: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</p>
                                <button 
                                    onClick={handleEndDemo}
                                    className="mt-2 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    End Demo
                                </button>
                            </div>
                        )}
                        
                        {!isDemo && (
                            <button 
                                onClick={handleStartDemo}
                                className="mb-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Start Demo
                            </button>
                        )}
                        
                        {displayUser && (
                            <div className="bg-gradient-to-r from-pink-100 to-purple-100 p-6 rounded-lg shadow-md mb-8">
                                <h2 className="text-2xl font-semibold mb-4 text-purple-700">User Information</h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <p className="text-gray-700"><span className="font-medium">Name:</span> {displayUser.firstname} {displayUser.lastname}</p>
                                    <p className="text-gray-700"><span className="font-medium">Email:</span> {displayUser.email}</p>
                                    <p className="text-gray-700"><span className="font-medium">ID:</span> {displayUser.id}</p>
                                    <p className="text-gray-700"><span className="font-medium">Credit Status:</span> {displayUser.credit_status || 'Not Available'}</p>
                                    <p className="text-gray-700"><span className="font-medium">Credit Limit:</span> ₹{displayUser.credit_limit || '0'}</p>
                                </div>
                            </div>
                        )}
                        
                        <div className="mb-8">
                            <h2 className="text-2xl font-semibold mb-4 text-purple-700">Recent Transactions</h2>
                            <div className="bg-gray-50 p-4 rounded-lg shadow">
                                {displayTransactions.map((transaction, index) => (
                                    <div key={index} className="mb-3 pb-3 border-b last:border-b-0">
                                        <p className="font-medium">{transaction.merchant_name}: ₹{transaction.amount}</p>
                                        <p className="text-sm text-gray-500">{transaction.date}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="w-full">
                            <h2 className="text-2xl font-semibold mb-4 text-purple-700">Quick Links</h2>
                            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {[
                                    { to: "/profile", text: "View Full Profile" },
                                    { to: "/user/orders", text: "View Order History" },
                                    { to: "/credit-check", text: "Check Credit Eligibility" },
                                    { to: "/remaining-debt", text: "View Remaining Debt" },
                                    { to: "/make-payment", text: "Make a Payment" },
                                    { to: "/demo-merchant", text: "Visit Demo Merchant" }
                                ].map((link, index) => (
                                    <li key={index}>
                                        <Link to={link.to} className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded shadow block text-center transition duration-300">
                                            {link.text}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;