import React, { createContext, useState, useEffect } from 'react';

export const DemoContext = createContext();

export const DemoProvider = ({ children }) => {
  const [isDemo, setIsDemo] = useState(false);
  const [demoExpiry, setDemoExpiry] = useState(null);
  const [demoCreditCheck, setDemoCreditCheck] = useState(null);
  const [demoTransactions, setDemoTransactions] = useState([]);
  const [creditCheckDone, setCreditCheckDone] = useState(false);

  useEffect(() => {
    let timer;
    if (isDemo && demoExpiry) {
      timer = setTimeout(() => {
        endDemo();
      }, 30 * 60 * 1000); // 30 minutes
    }
    return () => clearTimeout(timer);
  }, [isDemo, demoExpiry]);

  const startDemo = () => {
    setIsDemo(true);
    setDemoExpiry(new Date(new Date().getTime() + 30 * 60000));
    setCreditCheckDone(false);
    setDemoCreditCheck(null);
    setDemoTransactions([]);
  };

  const endDemo = () => {
    setIsDemo(false);
    setDemoExpiry(null);
    setCreditCheckDone(false);
    setDemoCreditCheck(null);
    setDemoTransactions([]);
  };

  const updateDemoCreditCheck = (result) => {
    setDemoCreditCheck(result);
    setCreditCheckDone(true);
  };

  const addDemoTransaction = (transaction) => {
    setDemoTransactions(prev => [transaction, ...prev]);
  };

  return (
    <DemoContext.Provider value={{
      isDemo,
      demoExpiry,
      demoCreditCheck,
      demoTransactions,
      creditCheckDone,
      startDemo,
      endDemo,
      updateDemoCreditCheck,
      addDemoTransaction
    }}>
      {children}
    </DemoContext.Provider>
  );
};