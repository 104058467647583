// src/components/ContactUs.js

import React from 'react';

const Contactus = () => {
    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center py-6">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
                <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
                <p className="text-gray-600 mb-4">
                    If you have any questions or need support, please feel free to contact us at:
                </p>
                <p className="text-blue-500 font-bold">admin@fractanow.com</p>
            </div>
        </div>
    );
};

export default Contactus;
