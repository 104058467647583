import React from 'react';
import { Route } from 'react-router-dom';
import MerchantDashboard from '../MerchantComponents/MerchantDashboard';
import TransactionHistory from '../MerchantComponents/TransactionHistory';
import TransactionDetails from '../MerchantComponents/TransactionDetails';
import MerchantProfile from '../MerchantComponents/MerchantProfile';
import AnalyticsDashboard from '../MerchantComponents/AnalyticsDashboard';
import MerchantOrders from '../MerchantComponents/MerchantOrders';
import MerchantCustomers from '../MerchantComponents/MerchantCustomers';

const MerchantRoutes = [
  <Route key="dashboard" path="/merchant/dashboard" element={<MerchantDashboard />} />,
  <Route key="transactions" path="/merchant/transactions" element={<TransactionHistory />} />,
  <Route key="transaction-details" path="/merchant/transactions/:transactionId" element={<TransactionDetails />} />,
  <Route key="profile" path="/merchant/profile" element={<MerchantProfile />} />,
  <Route key="analytics" path="/merchant/analytics" element={<AnalyticsDashboard />} />,
  <Route key="orders" path="/merchant/orders" element={<MerchantOrders />} />,
  <Route key="customers" path="/merchant/customers" element={<MerchantCustomers />} />
];

export default MerchantRoutes;