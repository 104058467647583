import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">About Fracta</h3>
            <p className="text-sm">Empowering purchases with AI-driven credit solutions.</p>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="text-sm">
              <li><Link to="/how-it-works" className="hover:text-gray-300">How It Works</Link></li>
              <li><Link to="/contact-us" className="hover:text-gray-300">Contact Us</Link></li>
              <li><Link to="/terms" className="hover:text-gray-300">Terms of Service</Link></li>
              <li><Link to="/privacy" className="hover:text-gray-300">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-white hover:text-gray-300"><i className="fab fa-facebook-f"></i></a>
              <a href="#" className="text-white hover:text-gray-300"><i className="fab fa-twitter"></i></a>
              <a href="#" className="text-white hover:text-gray-300"><i className="fab fa-linkedin-in"></i></a>
              <a href="#" className="text-white hover:text-gray-300"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 text-sm text-center">
          © {new Date().getFullYear()} Fracta. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;