import React, { useState } from 'react';
import { put } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
            await put('/profile/change-password', { password });
            alert('Password updated successfully');
            navigate('/profile');
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };

    return (
        <div>
            <h1>Change Password</h1>
            <form onSubmit={handleChangePassword}>
                <input
                    type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Change Password</button>
            </form>
        </div>
    );
};

export default ChangePassword;
