import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../services/api';

const TransactionHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await get('/merchant/transactions');
                setTransactions(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching transactions:', error);
                setError('Failed to fetch transactions');
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    if (loading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
    if (error) return <div className="flex items-center justify-center min-h-screen text-red-500">{error}</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-6">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-6xl">
                <h1 className="text-2xl font-bold mb-4">Transaction History</h1>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Transaction ID</th>
                                <th className="py-2 px-4 border-b">Amount</th>
                                <th className="py-2 px-4 border-b">Date</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map(transaction => (
                                <tr key={transaction.id} className="text-center">
                                    <td className="py-2 px-4 border-b">{transaction.id}</td>
                                    <td className="py-2 px-4 border-b">${transaction.amount}</td>
                                    <td className="py-2 px-4 border-b">{new Date(transaction.date).toLocaleDateString()}</td>
                                    <td className="py-2 px-4 border-b">{transaction.status}</td>
                                    <td className="py-2 px-4 border-b">
                                        <Link to={`/merchant/transactions/${transaction.id}`} className="text-blue-500 hover:text-blue-700">
                                            View Details
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TransactionHistory;