import React, { useEffect, useState } from 'react';
import { get } from '../../services/api';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const AnalyticsDashboard = () => {
    const [analytics, setAnalytics] = useState({
        total_sales: 0,
        total_revenue: 0,
        monthly_data: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchAnalytics = async () => {
          try {
            const response = await get('/merchant/analytics');
            setAnalytics(response.data);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching analytics:', error);
            setError('Failed to fetch analytics');
            setLoading(false);
          }
        };

        fetchAnalytics();
    }, []);

    if (loading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
    if (error) return <div className="flex items-center justify-center min-h-screen text-red-500">{error}</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-6">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-6xl">
                <h1 className="text-2xl font-bold mb-4">Analytics Dashboard</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
                    <div className="bg-blue-100 p-4 rounded-lg">
                        <h2 className="text-lg font-semibold mb-2">Total Sales</h2>
                        <p className="text-3xl font-bold">{analytics.total_sales}</p>
                    </div>
                    <div className="bg-green-100 p-4 rounded-lg">
                        <h2 className="text-lg font-semibold mb-2">Total Revenue</h2>
                        <p className="text-3xl font-bold">${analytics.total_revenue.toFixed(2)}</p>
                    </div>
                </div>
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">Monthly Sales and Revenue</h2>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={analytics.monthly_data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Legend />
                            <Line yAxisId="left" type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line yAxisId="right" type="monotone" dataKey="revenue" stroke="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    <h2 className="text-xl font-semibold mb-4">Monthly Breakdown</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b">Month</th>
                                    <th className="py-2 px-4 border-b">Sales</th>
                                    <th className="py-2 px-4 border-b">Revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {analytics.monthly_data.map((data, index) => (
                                    <tr key={index} className="text-center">
                                        <td className="py-2 px-4 border-b">{data.month}</td>
                                        <td className="py-2 px-4 border-b">{data.sales}</td>
                                        <td className="py-2 px-4 border-b">${data.revenue.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsDashboard;