import React, { useEffect, useState } from 'react';
import { get, put } from '../../services/api';

const MerchantProfile = () => {
    const [profile, setProfile] = useState({
        email: '',
        business_name: '',
        contact_number: '',
        address: ''
    });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await get('/merchant/profile');
            setProfile(response.data);
            setLoading(false);
            checkForIncompleteProfile(response.data);
        } catch (error) {
            console.error('Error fetching profile:', error);
            setMessage('Error fetching profile. Please try again.');
            setLoading(false);
        }
    };

    const checkForIncompleteProfile = (data) => {
        if (!data.business_name || !data.contact_number || !data.address) {
            setShowUpdatePrompt(true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await put('/merchant/profile', profile);
            setMessage('Profile updated successfully');
            setShowUpdatePrompt(false);
        } catch (error) {
            setMessage('Error updating profile. Please try again.');
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="container mx-auto p-4 pt-32">
            <h1 className="text-2xl font-bold mb-4 pt-32">Merchant Profile</h1>
            {showUpdatePrompt && (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
                    <p className="font-bold">Please update your profile</p>
                    <p>Some information is missing from your profile. Please fill in the missing details.</p>
                </div>
            )}
            {message && <p className="text-green-500 mb-4">{message}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={profile.email}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                        readOnly
                    />
                </div>
                <div>
                    <label htmlFor="business_name" className="block text-sm font-medium text-gray-700">Business Name</label>
                    <input
                        type="text"
                        name="business_name"
                        id="business_name"
                        value={profile.business_name}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="contact_number" className="block text-sm font-medium text-gray-700">Contact Number</label>
                    <input
                        type="tel"
                        name="contact_number"
                        id="contact_number"
                        value={profile.contact_number}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
                    <textarea
                        name="address"
                        id="address"
                        value={profile.address}
                        onChange={handleChange}
                        rows="3"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Update Profile
                </button>
            </form>
        </div>
    );
};

export default MerchantProfile;