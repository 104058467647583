import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DemoContext } from '../../DemoContext';

const DemoMerchantPage = () => {
    const [selectedPayment, setSelectedPayment] = useState('');
    const navigate = useNavigate();
    const { isDemo, addDemoTransaction } = useContext(DemoContext);

    const handleCheckout = () => {
        if (selectedPayment === 'fracta') {
            if (isDemo) {
                addDemoTransaction({
                    merchant_name: 'Demo Merchant',
                    amount: 15000,
                    date: new Date().toISOString()
                });
                navigate('/dashboard');
            } else {
                navigate('/demo-fracta-login');
            }
        } else {
            alert('Please select Fracta for this demo');
        }
    };

    return (
        <div className="max-w-2xl mx-auto mt-24 p-6 bg-white rounded-lg shadow-xl">
            <h2 className="text-2xl font-bold mb-6">Demo Merchant Checkout</h2>
            
            <div className="mb-6">
                <h3 className="text-xl font-semibold">Product: Premium Headphones</h3>
                <p className="text-gray-600">Price: ₹15,000</p>
            </div>

            <div className="mb-6">
                <h4 className="text-lg font-semibold mb-2">Select Payment Method</h4>
                <div>
                    <label className="inline-flex items-center">
                        <input
                            type="radio"
                            className="form-radio"
                            name="paymentMethod"
                            value="fracta"
                            checked={selectedPayment === 'fracta'}
                            onChange={() => setSelectedPayment('fracta')}
                        />
                        <span className="ml-2">Pay with Fracta</span>
                    </label>
                </div>
                <div>
                    <label className="inline-flex items-center">
                        <input
                            type="radio"
                            className="form-radio"
                            name="paymentMethod"
                            value="card"
                            checked={selectedPayment === 'card'}
                            onChange={() => setSelectedPayment('card')}
                        />
                        <span className="ml-2">Credit/Debit Card</span>
                    </label>
                </div>
            </div>

            <button
                onClick={handleCheckout}
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Proceed to Checkout
            </button>
        </div>
    );
};

export default DemoMerchantPage;