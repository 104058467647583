import React from 'react';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Step = ({ title, description, image, imageAlt, reverse }) => (
  <motion.div 
    className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center mb-16`}
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="md:w-1/2 mb-8 md:mb-0">
      <LazyLoadImage
        src={image}
        alt={imageAlt}
        effect="blur"
        className="rounded-lg shadow-xl w-full h-auto"
      />
    </div>
    <div className="md:w-1/2 md:px-8">
      <h3 className="text-3xl font-bold mb-4 text-blue-800">{title}</h3>
      <p className="text-xl text-gray-700 leading-relaxed">{description}</p>
    </div>
  </motion.div>
);

const HowItWorks = () => {
  const steps = [
    {
      title: "Create Your Account",
      description: "Sign up for Fracta in minutes. We'll need some basic information to get you started on your journey to smarter purchasing.",
      image: "/create-account.jpg",
      imageAlt: "Person creating an account on a laptop"
    },
    {
      title: "Apply for Credit",
      description: "Complete our simple credit application. We use advanced algorithms to assess your creditworthiness quickly and fairly.",
      image: "/apply-credit.jpg",
      imageAlt: "Person filling out a credit application"
    },
    {
      title: "AI-Powered Approval",
      description: "Our cutting-edge AI evaluates your application, considering various factors to determine your credit limit. Most decisions are made within minutes.",
      image: "/ai-approval.jpg",
      imageAlt: "Futuristic representation of AI processing data"
    },
    {
      title: "Shop with Approved Merchants",
      description: "Once approved, browse our network of trusted merchants. Your credit limit is ready to use for the products and services you need.",
      image: "/shop-merchants.jpg",
      imageAlt: "Person shopping online with various merchant logos"
    },
    {
      title: "Flexible Repayment",
      description: "Enjoy the flexibility of our Buy Now, Pay Later model. Spread your payments over time in a way that suits your budget.",
      image: "/flexible-repayment.jpg",
      imageAlt: "Calendar showing payment schedule"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold text-blue-900 mb-6">How Fracta Works</h1>
          <p className="text-2xl text-blue-700 mb-10">Experience the future of payments with our AI-powered platform</p>
        </motion.div>

        <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12">
          {steps.map((step, index) => (
            <Step key={index} {...step} reverse={index % 2 !== 0} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;