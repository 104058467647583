import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Authcontext';
import Logo from '../Logo';

const UserNavBar = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className="bg-gradient-to-r from-yellow-200 via-pink-200 to-blue-200 text-white shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <Logo size="medium" />
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <Link to="/dashboard" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">Dashboard</Link>
            <Link to="/profile" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">Profile</Link>
            <button onClick={handleLogout} className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">Logout</button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default UserNavBar;
