import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Authcontext';
import Logo from '../Logo';

const AdminNavBar = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  return (
    <nav className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <Link to="/admin/dashboard" className="flex-shrink-0">
              <Logo size="medium" />
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <Link to="/admin/dashboard" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-75">Dashboard</Link>
            <Link to="/admin/users" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-75">Users</Link>
            <Link to="/admin/merchants" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-75">Merchants</Link>
            <Link to="/admin/transactions" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-75">Transactions</Link>
            <Link to="/admin/create-transaction" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-75">Create Transaction</Link>
            <button
              onClick={handleLogout}
              className="px-3 py-2 rounded-md text-sm font-medium bg-red-600 hover:bg-red-700 transition-colors duration-300"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavBar;