import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, post } from '../../services/api';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Input } from '../ui/Input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Button } from '../ui/Button';

const MakePayment = () => {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMerchants = async () => {
        try {
            const response = await get('/payment/merchants');
            setMerchants(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching merchants:', error);
            setError('Failed to fetch merchants. Please try again.');
            setLoading(false);
        }
    };

    fetchMerchants();
  }, []);

  const handleMerchantChange = (merchantId) => {
    setSelectedMerchant(merchantId);
    const selectedMerchantData = merchants.find(m => m.id === merchantId);
    if (selectedMerchantData) {
      setAmount(selectedMerchantData.amount_owed.toFixed(2));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await post('/make-payment', {
            merchant_id: selectedMerchant,
            amount: parseFloat(amount),
            payment_date: paymentDate
        });
        console.log('Payment submitted:', response.data);
        navigate('/dashboard');
    } catch (error) {
        console.error('Error submitting payment:', error);
        setError('Failed to submit payment. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="min-h-screen bg-gray-100 p-24">
      <div className="max-w-2xl mx-auto">
        <Card className="shadow-lg">
          <CardHeader className="p-6">
            <h2 className="text-2xl font-semibold">Make a Payment</h2>
          </CardHeader>
          <CardContent className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="merchant" className="block text-sm font-medium text-gray-700 mb-2">
                  Select Merchant
                </label>
                <Select onValueChange={handleMerchantChange} value={selectedMerchant}>
                  <SelectTrigger id="merchant" className="w-full">
                    <SelectValue placeholder="Select a merchant" />
                  </SelectTrigger>
                  <SelectContent>
                    {merchants.map((merchant) => (
                      <SelectItem key={merchant.id} value={merchant.id}>
                        {merchant.business_name} (Owed: ₹{merchant.amount_owed.toFixed(2)})
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-2">
                  Amount
                </label>
                <Input
                  id="amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter amount"
                  required
                  className="w-full"
                />
              </div>

              <div>
                <label htmlFor="paymentDate" className="block text-sm font-medium text-gray-700 mb-2">
                  Payment Date
                </label>
                <Input
                  id="paymentDate"
                  type="date"
                  value={paymentDate}
                  onChange={(e) => setPaymentDate(e.target.value)}
                  required
                  className="w-full"
                />
              </div>

              <Button type="submit" className="w-full">
                Submit Payment
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default MakePayment;