import React from 'react';

const logo = process.env.PUBLIC_URL + '/logo.png';

const Logo = ({ size }) => {
  let sizeClass;
  switch (size) {
    case 'huge':
      sizeClass = 'h-156'; // Very large size
      break;
    case 'large':
      sizeClass = 'h-36'; // Large size
      break;
    case 'medium':
      sizeClass = 'h-20'; // Medium size
      break;
    case 'small':
      sizeClass = 'h-16'; // Small size
      break;
    default:
      sizeClass = 'h-12'; // Default size
  }
  return <img src={logo} alt="Fracto Logo" className={`${sizeClass} mx-auto`} />;
};

export default Logo;
