import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DemoContext } from '../../DemoContext';

const DemoLogin = () => {
    const navigate = useNavigate();
    const { startDemo } = useContext(DemoContext);

    useEffect(() => {
        // Auto-login for demo
        const performDemoLogin = async () => {
            // Simulate a brief delay
            await new Promise(resolve => setTimeout(resolve, 1000));
            startDemo();
            navigate('/dashboard');
        };

        performDemoLogin();
    }, [navigate, startDemo]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-2xl font-bold mb-4">Demo Login</h1>
                <p>Logging you in automatically...</p>
            </div>
        </div>
    );
};

export default DemoLogin;