import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Authcontext';
import Logo from '../Logo';

const MerchantNavBar = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/merchant/login');
  };

  return (
    <nav className="bg-gradient-to-r from-yellow-200 via-pink-200 to-blue-200 h-20 fixed top-0 left-0 right-0 z-50 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold ml-2 text-black">
              <Logo size="medium" />
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <Link to="/merchant/dashboard" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:bg-indigo-500 hover:text-white transition-colors">Dashboard</Link>
            <Link to="/merchant/profile" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:bg-indigo-500 hover:text-white transition-colors">Profile</Link>
            <Link to="/merchant/orders" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:bg-indigo-500 hover:text-white transition-colors">Orders</Link>
            <Link to="/merchant/customers" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:bg-indigo-500 hover:text-white transition-colors">Customers</Link>
            <button onClick={handleLogout} className="px-3 py-2 rounded-md text-sm font-medium text-black hover:bg-indigo-500 hover:text-white transition-colors">Logout</button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MerchantNavBar;