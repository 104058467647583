import React from 'react';
import { Route } from 'react-router-dom';
import UserDashboard from '../UserComponents/UserDashboard';
import Profile from '../UserComponents/Profile';
import ChangePassword from '../UserComponents/ChangePassword';
import UserOrders from '../UserComponents/UserOrders';
import CreditCheck from '../UserComponents/CreditCheck';
import InstallmentPlansList from '../UserComponents/InstallmentPlansList';
import InstallmentPlanDetails from '../UserComponents/InstallmentPlanDetails';
import MakePayment from '../UserComponents/MakePayment';
import UserProfile from '../UserComponents/UserProfile';
import RemainingDebt from '../UserComponents/RemainingDebt';

const UserRoutes = [
  <Route key="dashboard" path="/dashboard" element={<UserDashboard />} />,
  <Route key="profile" path="/profile" element={<Profile />} />,
  <Route key="user-profile" path="/user/profile" element={<UserProfile />} />,
  <Route key="change-password" path="/change-password" element={<ChangePassword />} />,
  <Route key="orders" path="/user/orders" element={<UserOrders />} />,
  <Route key="credit-check" path="/credit-check" element={<CreditCheck />} />,
  <Route key="installment-plans" path="/installment-plans" element={<InstallmentPlansList />} />,
  <Route key="installment-plan-details" path="/installment-plan/:planId" element={<InstallmentPlanDetails />} />,
  <Route key="make-payment" path="/make-payment" element={<MakePayment />} />,
  <Route key="remaining-debt" path="/remaining-debt" element={<RemainingDebt />} />
];

export default UserRoutes;