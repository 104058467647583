// src/hooks/useDemoTimer.js
import { useState, useEffect, useContext } from 'react';
import { DemoContext } from '../DemoContext';

export const useDemoTimer = () => {
    const { isDemo, demoExpiry, endDemo } = useContext(DemoContext);
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        if (!isDemo || !demoExpiry) {
            setTimeLeft(0);
            return;
        }

        const timer = setInterval(() => {
            const now = new Date();
            const expiryTime = new Date(demoExpiry);
            const difference = expiryTime - now;

            if (difference > 0) {
                setTimeLeft(Math.floor(difference / 1000));
            } else {
                clearInterval(timer);
                endDemo();
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [isDemo, demoExpiry, endDemo]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return { timeLeft, formattedTimeLeft: formatTime(timeLeft) };
};