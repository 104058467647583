import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../services/api';
import { Card, CardHeader, CardContent } from '../ui/card';

const AdminUserDetails = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await get(`/admin/users/${id}`);
        setUser(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user details:', err);
        setError('Failed to fetch user details');
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!user) return <div>User not found</div>;

  return (
    <Card className="max-w-2xl mx-auto mt-8">
      <CardHeader>
        <h2 className="text-2xl font-bold">User Details</h2>
      </CardHeader>
      <CardContent>
        <dl className="grid grid-cols-2 gap-4">
          <dt className="font-semibold">ID:</dt>
          <dd>{user.id}</dd>
          <dt className="font-semibold">Email:</dt>
          <dd>{user.email}</dd>
          <dt className="font-semibold">Name:</dt>
          <dd>{user.firstname} {user.lastname}</dd>
          <dt className="font-semibold">Credit Status:</dt>
          <dd>{user.credit_status}</dd>
          <dt className="font-semibold">Credit Limit:</dt>
          <dd>${user.credit_limit}</dd>
        </dl>
      </CardContent>
    </Card>
  );
};

export default AdminUserDetails;