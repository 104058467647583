import React from 'react';

export const Table = ({ children, className, ...props }) => (
  <div className="overflow-x-auto">
    <table className={`min-w-full bg-white ${className}`} {...props}>
      {children}
    </table>
  </div>
);

export const TableHeader = ({ children, className, ...props }) => (
  <thead className={`bg-gray-100 ${className}`} {...props}>
    {children}
  </thead>
);

export const TableBody = ({ children, className, ...props }) => (
  <tbody className={className} {...props}>
    {children}
  </tbody>
);

export const TableRow = ({ children, className, ...props }) => (
  <tr className={`border-b ${className}`} {...props}>
    {children}
  </tr>
);

export const TableHead = ({ children, className, ...props }) => (
  <th className={`py-2 px-4 text-left ${className}`} {...props}>
    {children}
  </th>
);

export const TableCell = ({ children, className, ...props }) => (
  <td className={`py-2 px-4 ${className}`} {...props}>
    {children}
  </td>
);