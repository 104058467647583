import React, { useEffect, useState } from 'react';
import { get } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import UserNavBar from './UserNavBar';
import UserTransactions from './UserTransactions';
import NotificationBell from './NotificationBell'; // Assuming you have this component

const UserProfile = () => {
    const [profile, setProfile] = useState({
        email: '',
        name: '',
        contact_number: '',
        address: '',
        credit_status: '',
        credit_limit: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await get('/user/profile');
                setProfile(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile:', error);
                setError('Error fetching profile');
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const getInitial = (name) => {
        return name ? name[0].toUpperCase() : '';
    };

    if (loading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;

    return (
        <>
            <UserNavBar />
            <div className="pt-24 min-h-screen bg-gradient-to-r from-[#FFF9C4] via-[#F8BBD0] to-[#F48FB1] flex items-center justify-center py-6">
                <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center w-full max-w-2xl relative">
                    <div className="absolute top-4 right-4">
                        <NotificationBell />
                    </div>
                    <div className="w-32 h-32 bg-gray-300 rounded-full flex items-center justify-center text-4xl font-bold text-white mb-4">
                        {getInitial(profile.name)}
                    </div>
                    <h1 className="text-2xl font-bold text-center">{profile.name}</h1>
                    <p className="text-gray-600 text-center mt-1">{profile.email}</p>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded shadow mt-4"
                        onClick={() => navigate('/change-password')}
                    >
                        Change Password
                    </button>
                    
                    <div className="w-full mt-8">
                        <UserTransactions />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserProfile;