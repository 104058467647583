import React from 'react';
import { Route } from 'react-router-dom';
import AdminDashboard from '../AdminComponents/AdminDashboard';
import AdminUsers from '../AdminComponents/AdminUsers';
import AdminMerchants from '../AdminComponents/AdminMerchants';
import AdminTransactions from '../AdminComponents/AdminTransactions';
import AdminCreateTransaction from '../AdminComponents/AdminCreateTransaction';
import AdminUserDetails from '../AdminComponents/AdminUserDetails';
import AdminMerchantDetails from '../AdminComponents/AdminMerchantDetails';
import AdminTransactionDetails from '../AdminComponents/AdminTransactionDetails';
import AdminWaitlist from '../AdminComponents/AdminWaitlist';

const AdminRoutes = [
  <Route key="dashboard" path="/admin/dashboard" element={<AdminDashboard />} />,
  <Route key="users" path="/admin/users" element={<AdminUsers />} />,
  <Route key="user-details" path="/admin/users/:id" element={<AdminUserDetails />} />,
  <Route key="merchants" path="/admin/merchants" element={<AdminMerchants />} />,
  <Route key="merchant-details" path="/admin/merchants/:id" element={<AdminMerchantDetails />} />,
  <Route key="transactions" path="/admin/transactions" element={<AdminTransactions />} />,
  <Route key="transaction-details" path="/admin/transactions/:id" element={<AdminTransactionDetails />} />,
  <Route key="create-transaction" path="/admin/create-transaction" element={<AdminCreateTransaction />} />,
  <Route key="waitlist" path="/admin/waitlist" element={<AdminWaitlist />} />,
];

export default AdminRoutes;