import React, { useState, useContext } from 'react';
import { post, get } from '../../services/api';
import { DemoContext } from '../../DemoContext';

const CreditCheck = () => {
    // State declarations
    const [formData, setFormData] = useState({
        credit_score: '',
        income: '',
        credit_history_length: '',
        employment_profile: 'Full-time',
        assessment_type: 'traditional'
    });
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [demoMode, setDemoMode] = useState('none');
    const { isDemo, updateDemoCreditCheck } = useContext(DemoContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Loading animation logic
    const runLoadingAnimation = async () => {
        for (let i = 0; i <= 100; i += 5) {
            await new Promise(resolve => setTimeout(resolve, 1500));
            setLoadingProgress(i);
        }
    };

    const getLoadingMessage = (progress) => {
        if (progress < 30) return "Analyzing credit history...";
        if (progress < 60) return "Evaluating income stability...";
        if (progress < 90) return "Calculating risk assessment...";
        return "Finalizing decision...";
    };

    // Main form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult(null);
        setLoading(true);
        setLoadingProgress(0);

        try {
            // Start loading animation
            const animationPromise = runLoadingAnimation();

            let response;
            if (isDemo || demoMode !== 'none') {
                // If in demo mode, use demo data
                await new Promise(resolve => setTimeout(resolve, 30000)); // 30s delay
                response = {
                    data: {
                        prediction: {
                            final_approved: true,
                            ml_score: 0.75,
                            confidence_score: 0.85
                        }
                    }
                };
                if (isDemo) {
                    updateDemoCreditCheck(response.data);
                }
            } else {
                // If not in demo mode, call actual FastAPI endpoint via Flask backend
                const payload = {
                    credit_score: parseInt(formData.credit_score),
                    income: parseFloat(formData.income),
                    credit_history_length: parseInt(formData.credit_history_length),
                    employment_profile: formData.employment_profile,
                    assessment_type: formData.assessment_type
                };
                
                console.log('Sending to FastAPI via Flask:', payload);
                
                // This hits your Flask endpoint which then calls FastAPI
                response = await post('/api/credit-assessment', payload);
                console.log('Response from FastAPI:', response);
            }

            await animationPromise; // Wait for loading animation to complete
            setResult(response.data);
            
        } catch (err) {
            console.error('Assessment error:', err);
            setError(`Assessment failed: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleTryDemo = (mode) => {
        setFormData({
            credit_score: '720',
            income: '500000',
            credit_history_length: '3',
            employment_profile: 'Full-time',
            assessment_type: 'traditional'
        });
        setDemoMode(mode);
        setResult(null);
        setError('');
    };

    // Loading animation component
    const LoadingAnimation = () => (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-xl text-center max-w-md w-full mx-4">
                <div className="mb-4">
                    <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto"></div>
                </div>
                <p className="text-lg font-medium text-gray-700">Processing Your Application</p>
                <div className="mt-4 relative pt-1">
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-100">
                        <div 
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500"
                            style={{ width: `${loadingProgress}%` }}
                        ></div>
                    </div>
                    <div className="text-sm text-gray-500">
                        {getLoadingMessage(loadingProgress)}
                    </div>
                </div>
            </div>
        </div>
    );

    // Result display component
    const renderResult = () => {
        if (!result?.prediction) return null;

        const prediction = result.prediction;
        
        return (
            <div className="mt-6 p-6 border rounded-md bg-gradient-to-r from-green-50 to-blue-50">
                <h3 className="text-2xl font-bold mb-4 text-center text-gray-800">
                    Assessment Result
                </h3>
                
                <div className="text-center space-y-4">
                    {prediction.final_approved ? (
                        <>
                            <div className="text-2xl font-bold text-green-600 mb-4">
                                Congratulations! 🎉
                            </div>
                            <div className="text-lg text-gray-700">
                                Your loan application has been approved!
                            </div>
                            <div className="mt-6 p-4 bg-green-50 rounded-lg border border-green-200">
                                <p className="text-lg font-semibold text-green-800">
                                    Loan Amount Approved: ₹10,000
                                </p>
                                <p className="text-sm text-green-600 mt-2">
                                    Your loan is ready for disbursement
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-xl font-medium text-red-600 mb-4">
                                Application Not Approved
                            </div>
                            <p className="text-gray-600">
                                We're sorry, but we cannot approve your loan application at this time. 
                                Please review your credit profile and try again after some time.
                            </p>
                        </>
                    )}
                </div>
            </div>
        );
    };

    // Main render
    return (
        <div className="max-w-md mx-auto mt-24 p-6 bg-white rounded-lg shadow-xl">
            <h2 className="text-2xl font-bold mb-6 text-center">Credit Assessment</h2>
            
            {(isDemo || demoMode !== 'none') && (
                <div className="mb-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                    <p className="font-bold">Demo Mode Active</p>
                    <p>This is a simulated credit assessment.</p>
                </div>
            )}

            <div className="mb-6 flex justify-center space-x-4">
                <button 
                    onClick={() => handleTryDemo('simulated')}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    Try Demo Assessment
                </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Assessment Type</label>
                    <select
                        name="assessment_type"
                        value={formData.assessment_type}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    >
                        <option value="traditional">Traditional Credit Assessment</option>
                        <option value="traditional_alternative" disabled>
                            Traditional + Alternative Data (Coming Soon)
                        </option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Credit Score</label>
                    <input 
                        type="number"
                        name="credit_score"
                        value={formData.credit_score}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                        min="300"
                        max="850"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Annual Income (₹)</label>
                    <input 
                        type="number"
                        name="income"
                        value={formData.income}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                        min="0"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Credit History Length (years)</label>
                    <input 
                        type="number"
                        name="credit_history_length"
                        value={formData.credit_history_length}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                        min="0"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Employment Profile</label>
                    <select
                        name="employment_profile"
                        value={formData.employment_profile}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    >
                        <option value="Full-time">Full-time</option>
                        <option value="Part-time">Part-time</option>
                        <option value="Self-employed">Self-employed</option>
                        <option value="Business-owner">Business Owner</option>
                        <option value="Unemployed">Unemployed</option>
                    </select>
                </div>

                <div className="mt-4 p-4 bg-blue-50 rounded-md">
                    <p className="text-blue-800 font-medium">Fixed Loan Amount: ₹10,000</p>
                    <p className="text-sm text-blue-600 mt-1">
                        Currently offering fixed amount loans only
                    </p>
                </div>

                <button 
                    type="submit" 
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Check Eligibility'}
                </button>
            </form>

            {error && (
                <div className="mt-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                    <p className="font-bold">Error</p>
                    <p>{error}</p>
                </div>
            )}

            {loading && <LoadingAnimation />}
            {result && renderResult()}
        </div>
    );
};

export default CreditCheck;