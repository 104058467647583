import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/api';
import { Link } from 'react-router-dom';
import { Bell } from 'lucide-react';

const NotificationBell = () => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await get('/notifications');
                setNotifications(response.data);
                const unread = response.data.filter(notification => !notification.read).length;
                setUnreadCount(unread);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
        // Set up an interval to fetch notifications every minute
        const intervalId = setInterval(fetchNotifications, 60000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const markAllAsRead = async () => {
        try {
            await post('/notifications/mark_all_as_read', {});
            setNotifications(notifications.map(n => ({ ...n, read: true })));
            setUnreadCount(0);
        } catch (error) {
            console.error('Error marking notifications as read:', error);
        }
    };

    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                className="p-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
                <Bell size={24} />
                {unreadCount > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                        {unreadCount}
                    </span>
                )}
            </button>
            {dropdownVisible && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-20">
                    <div className="py-2">
                        <div className="px-4 py-2 bg-gray-100 flex justify-between items-center">
                            <h3 className="text-sm font-semibold text-gray-800">Notifications</h3>
                            <button
                                onClick={markAllAsRead}
                                className="text-sm text-blue-600 hover:text-blue-800"
                            >
                                Mark all as read
                            </button>
                        </div>
                        <div className="max-h-64 overflow-y-auto">
                            {notifications.length > 0 ? (
                                notifications.map(notification => (
                                    <div
                                        key={notification.id}
                                        className={`px-4 py-2 hover:bg-gray-100 ${
                                            !notification.read ? 'bg-blue-50' : ''
                                        }`}
                                    >
                                        <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                                        <p className="text-sm text-gray-600">{notification.message}</p>
                                        <p className="text-xs text-gray-500 mt-1">
                                            {new Date(notification.created_at).toLocaleString()}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p className="px-4 py-2 text-sm text-gray-600">No notifications</p>
                            )}
                        </div>
                        <div className="px-4 py-2 bg-gray-100">
                            <Link
                                to="/notifications"
                                className="block text-center text-sm text-blue-600 hover:text-blue-800"
                            >
                                View all notifications
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationBell;