import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from '../../services/api';
import { Card, CardHeader, CardContent } from '../ui/card';
import { Button } from '../ui/Button';

const InstallmentPlansList = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await get('/api/installment-plans');
        setPlans(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching plans:', error);
        setError('Failed to fetch plans. Please try again.');
        setLoading(false);
      }
    };
    fetchPlans();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="min-h-screen bg-gray-100 p-24">
      <div className="max-w-2xl mx-auto space-y-8">
        <Card className="shadow-lg">
          <CardHeader className="p-6">
            <h2 className="text-2xl font-semibold">Your Installment Plans</h2>
          </CardHeader>
          <CardContent className="p-6">
            {plans.length > 0 ? (
              <div className="space-y-6">
                {plans.map(plan => (
                  <Card key={plan.id} className="shadow">
                    <CardContent className="p-6">
                      <h3 className="text-xl font-semibold mb-4">Plan #{plan.id}</h3>
                      <p className="mb-2">Total Amount: ₹{plan.total_amount.toFixed(2)}</p>
                      <p className="mb-2">Service Fee: ₹{plan.service_fee.toFixed(2)}</p>
                      <p className="mb-2">Initial Payment: ₹{plan.initial_payment.toFixed(2)}</p>
                      <p className="mb-2">Remaining Balance: ₹{plan.remaining_balance.toFixed(2)}</p>
                      <p className="mb-4">Status: {plan.status}</p>
                      <Button onClick={() => navigate(`/installment-plan/${plan.id}`)}>
                        View Details
                      </Button>
                    </CardContent>
                  </Card>
                ))}
              </div>
            ) : (
              <p>You have no active installment plans.</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default InstallmentPlansList;