import React from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../services/api';

const DemoPurchaseConfirmation = ({ product, amount, creditLimit }) => {
    const navigate = useNavigate();

    const handleConfirm = async () => {
        try {
            const response = await post('/api/demo-purchase', { product, amount });
            if (response.data.success) {
                navigate('/demo-dashboard');
            }
        } catch (err) {
            console.error('Purchase failed:', err);
        }
    };

    return (
        <div className="max-w-md mx-auto mt-24 p-6 bg-white rounded-lg shadow-xl">
            <h2 className="text-2xl font-bold mb-6 text-center">Confirm Your Purchase</h2>
            <div className="mb-4">
                <p className="font-semibold">Product: {product}</p>
                <p className="font-semibold">Amount: ₹{amount}</p>
                <p className="font-semibold">Available Credit: ₹{creditLimit}</p>
            </div>
            <button 
                onClick={handleConfirm}
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
                Confirm Purchase
            </button>
        </div>
    );
};

export default DemoPurchaseConfirmation;