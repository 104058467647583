import React, { useState, useEffect } from 'react';
import { get } from '../../services/api';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import UserNavBar from './UserNavBar';

const RemainingDebt = () => {
    const [debtInfo, setDebtInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDebtData = async () => {
            try {
                const response = await get('/api/user-debt');
                setDebtInfo(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching debt data:', err);
                setError(err.response?.data?.error || 'An unexpected error occurred');
                setLoading(false);
            }
        };

        fetchDebtData();
    }, []);

    if (loading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
    if (error) return <div className="flex items-center justify-center min-h-screen text-red-500">Error: {error}</div>;

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

    return (
        <>
            <UserNavBar />
            <div className="pt-24 min-h-screen bg-gray-100 flex flex-col items-center py-6">
                <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl">
                    <h1 className="text-2xl font-bold mb-4">Your Remaining Debt</h1>
                    
                    {debtInfo && (
                        <div className="mb-6">
                            <p className="text-lg font-semibold">Total Debt: ₹{typeof debtInfo.total_debt === 'number' ? debtInfo.total_debt.toFixed(2) : 'N/A'}</p>
                            <p className="text-md mb-4">Pending Payments: {debtInfo.pending_payments}</p>
                            {debtInfo.debt_breakdown && debtInfo.debt_breakdown.length > 0 ? (
                                <div className="h-64 mt-4">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart>
                                            <Pie
                                                data={debtInfo.debt_breakdown}
                                                dataKey="amount"
                                                nameKey="merchant"
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={80}
                                                fill="#8884d8"
                                                label
                                            >
                                                {debtInfo.debt_breakdown.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                            <Legend />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                            ) : (
                                <p>No debt breakdown available.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default RemainingDebt;