import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../services/api';
import { Card, CardHeader, CardContent } from '../ui/card';

const AdminTransactionDetails = () => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const response = await get(`/admin/transactions/${id}`);
        setTransaction(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching transaction details:', err);
        setError('Failed to fetch transaction details');
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!transaction) return <div>Transaction not found</div>;

  return (
    <Card className="max-w-2xl mx-auto mt-8">
      <CardHeader>
        <h2 className="text-2xl font-bold">Transaction Details</h2>
      </CardHeader>
      <CardContent>
        <dl className="grid grid-cols-2 gap-4">
          <dt className="font-semibold">ID:</dt>
          <dd>{transaction.id}</dd>
          <dt className="font-semibold">User:</dt>
          <dd>{transaction.user_email}</dd>
          <dt className="font-semibold">Merchant:</dt>
          <dd>{transaction.merchant_name}</dd>
          <dt className="font-semibold">Amount:</dt>
          <dd>${transaction.amount}</dd>
          <dt className="font-semibold">Date:</dt>
          <dd>{new Date(transaction.date).toLocaleString()}</dd>
          <dt className="font-semibold">Status:</dt>
          <dd>{transaction.status}</dd>
        </dl>
      </CardContent>
    </Card>
  );
};

export default AdminTransactionDetails;