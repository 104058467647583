import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AuthProvider } from './Authcontext';
import { DemoProvider } from './DemoContext'; // New import
import Layout from './components/Layout';
import GlobalNavBar from './components/GlobalNavBar';
import UserNavBar from './components/UserComponents/UserNavBar';
import MerchantNavBar from './components/MerchantComponents/MerchantNavBar';
import AdminNavBar from './components/AdminComponents/AdminNavBar';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';
import UserLogin from './components/Auth/UserLogin';
import UserRegister from './components/Auth/UserRegister';
import MerchantLogin from './components/Auth/MerchantLogin';
import MerchantRegister from './components/Auth/MerchantRegister';
import AdminLogin from './components/Auth/AdminLogin';
import UserRoutes from './components/UserRoutes/Index';
import MerchantRoutes from './components/MerchantRoutes/Index';
import AdminRoutes from './components/AdminRoutes/Index';
import UserProfileUpdate from './components/UserComponents/UserProfileUpdate';
import ContactUs from './components/Contactus';
import UserDashboard from './components/UserComponents/UserDashboard';
import HowItWorks from './components/HowItWorks';
import AdminUserDetails from './components/AdminComponents/AdminUserDetails';
import AdminMerchantDetails from './components/AdminComponents/AdminMerchantDetails';
import AdminTransactionDetails from './components/AdminComponents/AdminTransactionDetails';
import DemoMerchantPage from './components/DemoComponents/DemoMerchantPage'; // New import
import DemoCheckout from './components/DemoComponents/DemoCheckout'; // New import
import DemoPurchaseConfirmation from './components/DemoComponents/DemoPurchaseConfirmation'; // New import
import DemoLogin from './components/DemoComponents/DemoLogin';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <DemoProvider>
        <Router>
          <AppContent />
        </Router>
      </DemoProvider>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="page-transition" timeout={300}>
        <Routes location={location}>
          {/* Public routes */}
          <Route element={<Layout><GlobalNavBar /></Layout>}>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<UserLogin />} />
            <Route path="/register" element={<UserRegister />} />
            <Route path="/update_profile" element={<UserProfileUpdate />} />
            <Route path="/merchant/login" element={<MerchantLogin />} />
            <Route path="/merchant/register" element={<MerchantRegister />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
          </Route>

          {/* Protected User Routes */}
          <Route element={<ProtectedRoute userType="user" />}>
            <Route element={<Layout><UserNavBar /></Layout>}>
              <Route path="/dashboard" element={<UserDashboard />} />
              {UserRoutes}
              {/* Demo Routes */}
              <Route path="/demo-merchant" element={<DemoMerchantPage />} />
              <Route path="/demo-checkout" element={<DemoCheckout />} />
              <Route path="/demo-purchase-confirmation" element={<DemoPurchaseConfirmation />} />
              <Route path="/demo-fracta-login" element={<DemoLogin />} />
            </Route>
          </Route>

          {/* Protected Merchant Routes */}
          <Route element={<ProtectedRoute userType="merchant" />}>
            <Route element={<Layout><MerchantNavBar /></Layout>}>
              {MerchantRoutes}
            </Route>
          </Route>

          {/* Protected Admin Routes */}
          <Route element={<ProtectedRoute userType="admin" />}>
            <Route element={<Layout><AdminNavBar /></Layout>}>
              {AdminRoutes}
            </Route>
          </Route>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;