import React, { useState, useEffect } from 'react';
import { get } from '../../services/api'; // Import get function
import UserTransactions from './UserTransactions';
import { Link } from 'react-router-dom';
import NotificationBell from './NotificationBell'; // Import NotificationBell
import UserNavBar from './UserNavBar'; // Import UserNavBar component

const Profile = () => {
    const [user, setUser] = useState({});

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await get('/profile');
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, []);

    const getInitials = (name) => {
        return name ? name.charAt(0).toUpperCase() : '';
    };

    const profilePictureStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '128px',
        height: '128px',
        borderRadius: '50%',
        backgroundColor: '#ccc',
        fontSize: '64px',
        color: '#fff',
    };

    return (
        <>
            <UserNavBar /> {/* Add UserNavBar component */}
            <div className="pt-24 min-h-screen bg-gray-100 flex items-center justify-center py-6"> {/* Add padding to top */}
                <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center w-full max-w-md">
                    <NotificationBell /> {/* Add the NotificationBell component here */}
                    {user.profilePicture ? (
                        <img
                            className="w-32 h-32 rounded-full shadow-lg mt-4"
                            src={user.profilePicture}
                            alt="Profile"
                        />
                    ) : (
                        <div className="w-32 h-32 rounded-full shadow-lg mt-4" style={profilePictureStyle}>
                            {getInitials(user.firstname)}
                        </div>
                    )}
                    <h1 className="text-2xl font-bold mt-4">
                        {user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : 'User Name'}
                    </h1>
                    <p className="text-gray-600 mt-2">{user.email}</p>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded shadow mt-4"
                        onClick={() => window.location.href = '/change-password'}
                    >
                        Change Password
                    </button>
                    <div className="w-full mt-6">
                        <h3 className="text-xl font-bold mb-4">Transactions</h3>
                        <UserTransactions />
                    </div>
                    <div className="w-full mt-6">
                        <Link
                            to="/user/orders"
                            className="bg-gray-500 text-white px-4 py-2 rounded shadow block text-center mb-2"
                        >
                            View Order History
                        </Link>
                        <Link
                            to="/user/profile"
                            className="bg-gray-500 text-white px-4 py-2 rounded shadow block text-center"
                        >
                            Edit Profile
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
