import React, { useState, useEffect } from 'react';
import { get } from '../../services/api';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/Table';

const AdminWaitlist = () => {
  const [waitlist, setWaitlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchWaitlist = async () => {
      try {
        const response = await get('/admin/waitlist');
        setWaitlist(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching waitlist:', err);
        setError('Failed to fetch waitlist');
        setLoading(false);
      }
    };

    fetchWaitlist();
  }, []);

  if (loading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  if (error) return <div className="flex items-center justify-center min-h-screen text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Waitlist</h1>
      
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>ID</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Joined At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {waitlist.map((entry) => (
            <TableRow key={entry.id}>
              <TableCell>{entry.id}</TableCell>
              <TableCell>{entry.email}</TableCell>
              <TableCell>{entry.name || 'N/A'}</TableCell>
              <TableCell>{entry.type}</TableCell>
              <TableCell>{new Date(entry.created_at).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AdminWaitlist;