import React, { useEffect, useState } from 'react';
import { get } from '../../services/api';
import { Link } from 'react-router-dom';

const UserOrders = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await get('/user/orders');
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center py-6">
            <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center w-full max-w-4xl">
                <h2 className="text-2xl font-bold mb-4">Your Orders</h2>
                <div className="w-full overflow-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Order ID</th>
                                <th className="py-2 px-4 border-b">Merchant Name</th>
                                <th className="py-2 px-4 border-b">Amount</th>
                                <th className="py-2 px-4 border-b">Date</th>
                                <th className="py-2 px-4 border-b">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map(order => (
                                <tr key={order.id} className="text-center">
                                    <td className="py-2 px-4 border-b">{order.id}</td>
                                    <td className="py-2 px-4 border-b">{order.merchant_name}</td>
                                    <td className="py-2 px-4 border-b">₹{order.amount}</td>
                                    <td className="py-2 px-4 border-b">{order.date}</td>
                                    <td className="py-2 px-4 border-b">{order.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Link
                    to="/profile"
                    className="bg-gray-500 text-white px-4 py-2 rounded shadow block text-center mt-6"
                >
                    Back to Profile
                </Link>
            </div>
        </div>
    );
};

export default UserOrders;
