import React, { useState } from 'react';

export const Select = React.forwardRef(({ className, children, ...props }, ref) => (
  <select
    className={`border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
    ref={ref}
    {...props}
  >
    {children}
  </select>
));

export const SelectTrigger = React.forwardRef(({ className, children, ...props }, ref) => (
  <div className="relative">
    <select
      className={`appearance-none border border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
      ref={ref}
      {...props}
    >
      {children}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
));

export const SelectValue = ({ children }) => children;

export const SelectContent = ({ children }) => children;

export const SelectItem = React.forwardRef(({ className, children, ...props }, ref) => (
  <option
    className={`${className}`}
    ref={ref}
    {...props}
  >
    {children}
  </option>
));

export const SelectComponent = ({ onValueChange, value, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    onValueChange(e.target.value);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <SelectTrigger
        value={value}
        onClick={() => setIsOpen(!isOpen)}
        onChange={handleChange}
      >
        {children}
      </SelectTrigger>
      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg">
          <SelectContent>
            {children}
          </SelectContent>
        </div>
      )}
    </div>
  );
};