import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';

const WaitlistForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
  
    useEffect(() => {
      console.log('Current type:', type);
    }, [type]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');
      setSuccess('');
  
      console.log('Form data:', { name, email, type });
  
      if (!email || !type) {
        setError('Please fill in all fields');
        return;
      }
  
      try {
        const response = await axios.post('https://api.fractanow.com/waitlist', {
          name,
          email,
          type
        });
  
        console.log('Response:', response);
  
        if (response.status === 200) {
          setSuccess('Thank you for joining our waitlist!');
          setName('');
          setEmail('');
          setType('');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setError('An error occurred. Please try again.');
      }
    };
  
    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <select
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="">Select Type</option>
          <option value="user">User</option>
          <option value="merchant">Merchant</option>
        </select>
        <Button type="submit">Join Waitlist</Button>
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
      </form>
    );
  };
  
  export default WaitlistForm;