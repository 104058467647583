import React, { useEffect, useState } from 'react';
import { get } from '../../services/api';

const UserTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('No token found');
                return;
            }

            try {
                const response = await get('/user/transactions');
                setTransactions(response.data);
            } catch (error) {
                console.error('Error fetching transactions:', error.response ? error.response.data : error.message);
                setError('Error fetching transactions');
            }
        };

        fetchTransactions();
    }, []);

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div>
            <h3 className="text-xl font-bold mb-4">Transactions</h3>
            {transactions.length > 0 ? (
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="p-2 text-left">Amount</th>
                            <th className="p-2 text-left">Date</th>
                            <th className="p-2 text-left">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td className="p-2">₹{transaction.amount}</td>
                                <td className="p-2">{new Date(transaction.date).toLocaleString()}</td>
                                <td className="p-2">
                                    <span className={`px-2 py-1 rounded-full text-xs ${
                                        transaction.status === 'Completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                                    }`}>
                                        {transaction.status}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="text-gray-500">No transactions found</div>
            )}
        </div>
    );
};

export default UserTransactions;