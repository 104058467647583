import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const GlobalNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  let timeoutId = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutId.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutId.current = setTimeout(() => {
      setIsOpen(false);
    }, 500); // Delay of 500ms (0.5 seconds)
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <nav className="bg-gradient-to-r from-yellow-200 via-pink-200 to-blue-200 text-white shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <Logo size="medium" />
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <Link to="/how-it-works" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">How It Works</Link>
            <Link to="/contact-us" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">Contact Us</Link>
            <Link to="/login" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">Login</Link>
            <Link to="/register" className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">Register</Link>
            <div 
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={dropdownRef}
            >
              <button className="px-3 py-2 rounded-md text-sm font-medium text-black hover:text-[#FF9800]">
                Merchant
              </button>
              <div className={`absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                <Link to="/merchant/login" className="block px-4 py-2 text-sm text-gray-700 hover:bg-[#FFCDD2] hover:text-white">Merchant Login</Link>
                <Link to="/merchant/register" className="block px-4 py-2 text-sm text-gray-700 hover:bg-[#FFCDD2] hover:text-white">Merchant Register</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default GlobalNavBar;