import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../services/api';
import { Card, CardHeader, CardContent } from '../ui/card';

const AdminMerchantDetails = () => {
  const [merchant, setMerchant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const fetchMerchantDetails = async () => {
      try {
        const response = await get(`/admin/merchants/${id}`);
        setMerchant(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching merchant details:', err);
        setError('Failed to fetch merchant details');
        setLoading(false);
      }
    };

    fetchMerchantDetails();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!merchant) return <div>Merchant not found</div>;

  return (
    <Card className="max-w-2xl mx-auto mt-8">
      <CardHeader>
        <h2 className="text-2xl font-bold">Merchant Details</h2>
      </CardHeader>
      <CardContent>
        <dl className="grid grid-cols-2 gap-4">
          <dt className="font-semibold">ID:</dt>
          <dd>{merchant.id}</dd>
          <dt className="font-semibold">Email:</dt>
          <dd>{merchant.email}</dd>
          <dt className="font-semibold">Business Name:</dt>
          <dd>{merchant.business_name}</dd>
          <dt className="font-semibold">Contact Number:</dt>
          <dd>{merchant.contact_number}</dd>
          <dt className="font-semibold">Address:</dt>
          <dd>{merchant.address}</dd>
        </dl>
      </CardContent>
    </Card>
  );
};

export default AdminMerchantDetails;