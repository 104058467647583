import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../services/api';

const TransactionDetails = () => {
    const { transactionId } = useParams();
    const [transaction, setTransaction] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchTransactionDetails = async () => {
          try {
            const response = await get(`/merchant/transactions/${transactionId}`);
            setTransaction(response.data);
          } catch (error) {
            setMessage('Error fetching transaction details');
            console.error('Error fetching transaction details:', error);
          }
        };

        fetchTransactionDetails();
    }, [transactionId]);

    if (!transaction) {
        return <p>{message || 'Loading...'}</p>;
    }

    return (
        <div>
            <h1>Transaction Details</h1>
            <p>Transaction ID: {transaction.id}</p>
            <p>Amount: {transaction.amount}</p>
            <p>Date: {transaction.date}</p>
            <p>Status: {transaction.status}</p>
        </div>
    );
};

export default TransactionDetails;
